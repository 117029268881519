import React from "react";

import NotFoundComponent from "../components/404";

function NotFoundPage() {
  return (
    <NotFoundComponent />
  );
}

export default NotFoundPage;
