import React from "react";
import abductionIllustration from "../images/abduction-illustration.svg";

import Layout from "./layout";
import AdminLayout from "./admin/adminLayout";
import SEO from "./seo";
import { isLoggedIn } from "../utils/auth";

function NotFoundComponent(props)
{
  const content = (
    <div className="text-center">
      <img
        alt="Ghost getting abducted by aliens"
        className="block mx-auto w-1/2"
        src={abductionIllustration}
      />
      <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
        Looks like this page is a ghost that got abducted by aliens...
      </h2>
    </div>
  );

  if (props.isAdminPage && isLoggedIn())
  {
    return (
      <AdminLayout>
        <SEO title = "404: Not found" />
        { content }
      </AdminLayout>
    );
  }
  else
  {
    return (
      <Layout>
        <SEO title = "404: Not found" />
        { content }
      </Layout>
    );
  }
}

export default NotFoundComponent;
